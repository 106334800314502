<template>
  <div class="main-news">
    <content-wrapper-block>
      <div class="main-news__inline">
        <page-title>{{ title }}</page-title>
        <slider-navigation-arrows
          v-if="navigation"
          :active-next="svipeNext"
          :active-prev="svipePrev"
          :disable-prev="disableButtonPrev"
          :disable-next="disablButtonNext"
          @next="goToNextNews"
          @prev="goToPrevNews"
        />
      </div>
      <div class="main-news-slider">
        <swiper
          ref="newsSwiper"
          class="main-news-slider__swiper"
          watch-slides-progress
          :options="{
            spaceBetween: 30,
            slidesPerView: 'auto',
            centerSlides: true,
            pagination: {
              el: '.news-swiper-pagination',
              type: 'bullets',
              clickable: true,
            },
            breakpoints: {
              1440: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
                centeredSlides: true,
              },
              0: {
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 0,
              },
            },
          }"
        >
          <!-- Slide -->
          <swiper-slide
            v-for="(slide, index) in news"
            :key="index"
            class="main-news-slider__slide"
          >
            <card-news
              :id="slide.id"
              :id-card="'about__news__detail-' + slide.id"
              class="main-news-slider__card"
              :date="formatDate(slide.published_at)"
              :title="slide.title"
              :short-description="slide.short_content"
              :fill="true"
              :to="{
                name: 'InnerNewsPage',
                params: { slug: slide.slug },
              }"
            />
          </swiper-slide>
          <!-- pagination -->
          <swiper-red-pagination
            slot="pagination"
            pagination-white
            class="news-swiper-pagination"
          />
        </swiper>
      </div>
      <div class="main-news__all-news all-news">
        <router-link :to="{ name: 'NewsCatalogPage' }">
          <ui-button class="all-news__button"> Все новости </ui-button>
        </router-link>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import SliderNavigationArrows from "@/components/ui/SliderNavigationArrows";
import CardNews from "@/components/elements/CardNews";
import UiButton from "@/components/ui/UIButton";
import formatD from "@/mixins/formatD";
import SwiperRedPagination from "@/components/elements/SwiperRedPagination";
import axios from "axios";
// import { mapState } from 'vuex'

export default {
  name: "SliderNews",
  components: {
    SliderNavigationArrows,
    PageTitle,
    ContentWrapperBlock,
    CardNews,
    UiButton,
    SwiperRedPagination,
  },
  props: {
    title: {
      type: String,
      default: "Новости",
    },
    navigation: Boolean,
  },
  data: () => ({
    news: null,
    svipeNext: false,
    svipePrev: false,
    disableButtonPrev: false,
    disablButtonNext: false,
  }),
  created() {
    this.loadNews(
      process.env.VUE_APP_PROJECT_ID,
      process.env.VUE_APP_NEWS_ID,
      "ru",
      17,
      1
    );

    this.$nextTick(() => {
      this.$refs.newsSwiper.$swiper.on("slideNextTransitionStart", () => {
        this.disableButtonPrev = false;
      });
      this.$refs.newsSwiper.$swiper.on("slidePrevTransitionStart", () => {
        this.disablButtonNext = false;
      });
      this.$refs.newsSwiper.$swiper.on("slideNextTransitionStart", () => {
        this.svipeNext = true;
        this.svipePrev = false;
      });
      this.$refs.newsSwiper.$swiper.on("slidePrevTransitionStart", () => {
        this.svipeNext = false;
        this.svipePrev = true;
      });
      this.$refs.newsSwiper.$swiper.on("reachEnd", () => {
        this.disablButtonNext = true;
        this.disableButtonPrev = false;
      });
      this.$refs.newsSwiper.$swiper.on("reachBeginning", () => {
        this.disablButtonNext = false;
        this.disableButtonPrev = true;
      });
    });
  },
  methods: {
    loadNews(project, category, language, perPage, page) {
      axios
        .get(process.env.VUE_APP_API_ADMIN + "api/v1" + "/publications", {
          params: {
            project: project,
            category: category,
            language: language,
            per_page: perPage,
            page: page || 1,
          },
        })
        .then((response) => {
          this.news = response.data.data.slice(0, 12);
        });
    },
    goToNextNews() {
      this.$refs.newsSwiper.$swiper.updateSlides();
      this.$refs.newsSwiper.$swiper.slideNext();
    },
    goToPrevNews() {
      this.$refs.newsSwiper.$swiper.updateSlides();
      this.$refs.newsSwiper.$swiper.slidePrev();
    },
    formatDate(str) {
      return formatD(str, "dd.MM.yyyy");
    },
  },
};
</script>

<style lang="scss" scoped>
$main_news_bg: #000;

.main-news {
  background: $main_news_bg;
  color: invert($color: $main_news_bg);
  &__inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .slider-navigation {
      display: none;
      @media screen and (min-width: $screen-sm) {
        display: flex;
      }
    }
  }
  .news-swiper-pagination {
    margin: 20px auto;
    @media screen and (min-width: $screen-sm) {
      margin: 30px auto;
    }
  }
}
.all-news {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-news-slider {
  &__card {
    height: 218px;
    max-height: 218px;
    display: block;
  }

  &__slide {
    width: 85%;
    @media screen and (min-width: $screen-sm) {
      width: auto;
    }
  }
  .swiper-slide-next,
  .swiper-slide-prev {
    opacity: 0.5;
    z-index: -1;
    @media screen and (min-width: $screen-sm) {
      opacity: 1;
    }
  }
  &__swiper {
    @media screen and (max-width: $screen-sm) {
      overflow: visible;
    }

    // .swiper-slide {
    //   @media screen and (max-width: $screen-sm) {
    //   opacity: 0;
    //   transform: translateX(0px) scale(.8);
    // }
    // }

    .swiper-slide-active {
      @media screen and (max-width: $screen-sm) {
        opacity: 1;
        transform: none;
      }
    }

    .swiper-slide-prev {
      @media screen and (max-width: $screen-sm) {
        opacity: 0.5;
        transform: translateX(50px) scale(0.8);
      }
    }
    .swiper-slide-next {
      @media screen and (max-width: $screen-sm) {
        opacity: 0.5;
        transform: translateX(-50px) scale(0.8);
      }
    }
  }
  &__slide {
    @media screen and (max-width: $screen-sm) {
      width: 90%;
      transition: all 300ms ease-out;
    }
  }
}
</style>
