var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-news" },
    [
      _c("content-wrapper-block", [
        _c(
          "div",
          { staticClass: "main-news__inline" },
          [
            _c("page-title", [_vm._v(_vm._s(_vm.title))]),
            _vm.navigation
              ? _c("slider-navigation-arrows", {
                  attrs: {
                    "active-next": _vm.svipeNext,
                    "active-prev": _vm.svipePrev,
                    "disable-prev": _vm.disableButtonPrev,
                    "disable-next": _vm.disablButtonNext,
                  },
                  on: { next: _vm.goToNextNews, prev: _vm.goToPrevNews },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "main-news-slider" },
          [
            _c(
              "swiper",
              {
                ref: "newsSwiper",
                staticClass: "main-news-slider__swiper",
                attrs: {
                  "watch-slides-progress": "",
                  options: {
                    spaceBetween: 30,
                    slidesPerView: "auto",
                    centerSlides: true,
                    pagination: {
                      el: ".news-swiper-pagination",
                      type: "bullets",
                      clickable: true,
                    },
                    breakpoints: {
                      1440: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        centeredSlides: true,
                      },
                      0: {
                        slidesPerView: "auto",
                        centeredSlides: true,
                        spaceBetween: 0,
                      },
                    },
                  },
                },
              },
              [
                _vm._l(_vm.news, function (slide, index) {
                  return _c(
                    "swiper-slide",
                    { key: index, staticClass: "main-news-slider__slide" },
                    [
                      _c("card-news", {
                        staticClass: "main-news-slider__card",
                        attrs: {
                          id: slide.id,
                          "id-card": "about__news__detail-" + slide.id,
                          date: _vm.formatDate(slide.published_at),
                          title: slide.title,
                          "short-description": slide.short_content,
                          fill: true,
                          to: {
                            name: "InnerNewsPage",
                            params: { slug: slide.slug },
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                _c("swiper-red-pagination", {
                  staticClass: "news-swiper-pagination",
                  attrs: { slot: "pagination", "pagination-white": "" },
                  slot: "pagination",
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "main-news__all-news all-news" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "NewsCatalogPage" } } },
              [
                _c("ui-button", { staticClass: "all-news__button" }, [
                  _vm._v(" Все новости "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }